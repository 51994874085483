import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import intercomLogo from "./intercom-transparent-logo.svg"
import intercomFirstVersion from "./intercom-first-version.png"
import intercomMessenger from "./intercom-messenger.png"
{ /* Consider changing font color to subtle navy instead of black */}

function Top () {
  return (
    <div className="Top">
      <h1>Nothing Starts Finished</h1>
      <p>This website is dedicated to showing the unfinished beginnings of what
        looks finished today.<br /><br />
        Today's giants of industry, technology, business and... well, everything really... started off as yesterday's unpolished hacks and awkward experiments.
        <br /><br />
        Proof below.
      </p>
    </div>
  );
}

function Intercom () {
  return (
    <div className="companyShowcase">
    { /* images root index is to 'public' directory, adjacent to index.html */ }
      <img src={intercomLogo} className="logoImage" alt="Intercom black logo"/>
      <p className="companyText">Intercom is a suite of tools dedicated to helping companies help their customers. Basically it's as complete a set of marketing, messaging and customer support tools as you'll find. Founded in 2011, Intercom today is worth $1.6 billion and employs over 600 people. In the beginning though, Intercom started as a minor tool to let customers know when a web-app was having issues.
      </p>
      <div>
        <img className="nonLogoImage" src={intercomMessenger} alt="Screenshot of current Intercom messenger product"/>
        <p className="companyText">This is what Intercom looks like today, and even that's only a small part of the overall platform. <br /><br />The first version of Intercom is shown below, from 2010. See the textbox and star icon in the bottom right? That's it.
        </p>
        <img className="nonLogoImage" src={intercomFirstVersion} alt="Screenshot of early Intercom product"/>
      </div>
    </div>
  );
}

function Website () {
  return (
    <div>
      <Top />
      <hr className="introDivider"/>
      <Intercom />
    </div>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Website />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
